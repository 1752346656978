<template>
    <div class="wrapper" v-loading="loading">
        <div v-if="!userId" class="top-btn" ref="topBtn">
            <div>
                <el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
                    <el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"></el-option>
                </el-select>
                <el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
                <el-button size="small" type="primary" @click="Search()">搜索</el-button>
            </div>
            <div>
                <span class="demonstration">点赞时间：</span>
				<el-date-picker size="small" style="width: 230px" @change="Search()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
            </div>
        </div>
        <el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
            <el-table-column prop="vote_time" label="点赞时间">
              <template slot-scope="scope">{{ scope.row.vote_time*1000 | formatDate }}</template>
            </el-table-column>
            <el-table-column prop="member_head_img" label="头像"> <template slot-scope="scope">
                <img :src="scope.row.member_head_img || ''" style="width: 80px" alt="" />
              </template>
            </el-table-column>
            <el-table-column prop="member_id" label="用户id"></el-table-column>
            <el-table-column prop="member_name" label="用户昵称"></el-table-column>
            <el-table-column prop="desp" label="视频描述"></el-table-column>
            <el-table-column prop="org_name" label="机构"></el-table-column> 
            <el-table-column prop="city" label="机构城市"></el-table-column>
            <el-table-column prop="video_id" label="视频ID"></el-table-column>
        </el-table>
        <el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total"> </el-pagination>
    </div>
</template>
<script>
import { memberVoteList } from '@/api/base.js';
export default {
    name: "LikeLog",
    props: {
        userId: { // 用户列表详情里
            type: String,
            default: ''
        },
    },
    data () {
        return {
            loading: true,
            tableHeight: this.$constant.maxHeight,
            searchGist:  [{
                value: 'wx_nickname',
                label: '昵称'
            }, {
                value: 'phone',
                label: '手机号'
            }, {
                value: 'org_name',
                label: '机构名称'
            }, {
                value: 'video_id',
                label: '视频id'
            }],
            // 列表
            search: {
                search_gist: 'wx_nickname',
                desp: '',
                time: ''
            },
            // 列表
            total: 0,
            limit: 10,
            currentPage: 1,
            list: [],
            dialogVisible: false,
            id: 0
        }
    },
    mounted() {
        this.getList()
        this.tableHeight = this.userId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
        window.onresize = this.$common.debounce(()=>{
            this.tableHeight = this.userId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
        }, 1000)
    },
    methods: {
      // 查看详情
      showDetail (id) {
          this.$refs.detail.getUserDetail(id)
          this.id = id
          this.dialogVisible = true
      },
      // 获取列表
      getList () {
           let data = {}
           if(this.userId) {
                data = {
                    limit: this.limit,
                    page: this.currentPage,
                    member_id: this.userId,
                }
            }else { 
                data = {
                    limit: this.limit,
                    page: this.currentPage,
                    wx_nickname: this.search.search_gist == 'wx_nickname' ? this.search.desp : null,
                    phone: this.search.search_gist == 'phone' ? this.search.desp : null,
                    org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
                    video_id: this.search.search_gist == 'video_id' ? this.search.desp : null,
                    start_time: this.$common.setSearchTime(this.search.time)[0],
                    end_time: this.$common.setSearchTime(this.search.time)[1]
                }
            }
            memberVoteList(data).then((res) => {
                this.total = res.data.total;
                this.list = res.data.data;
                this.loading = false
            }).catch((err) => {
                this.loading = false;
                console.log(err, 222)
            })
      },
      // 筛选
      Search () {
          this.loading = true
          this.currentPage = 1
          this.getList()
      },
      // 页码修改
      handleCurrentChange (val) {
          this.loading = true
          this.currentPage = val;
          this.getList();
      },
    }
};
</script>
