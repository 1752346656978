<template>
    <div>
        <Coupon :userId="userId"/>
    </div>
</template>
<script>
import Coupon from '../../coupon/order.vue'
export default {
    name: "UserCoupon",
    data() {
        return {
            userId: this.$route.query.id,
        }
    },
    components: {
        Coupon
    },
    created() {
        
    },
    methods: {
       
    }
};
</script>