<template>
	<div class="wrapper">
        <el-page-header @back="$router.push({path: '/user/userlist', query: {group: '1',item: '1-1', keepAlive: '1'}})" content="用户详情"></el-page-header>
		<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">用户信息</el-menu-item>
            <el-menu-item index="2">订单</el-menu-item>
            <el-menu-item index="6">优惠券订单</el-menu-item>
            <el-menu-item index="3">点赞日志</el-menu-item>
            <el-menu-item index="4">红包抵用金</el-menu-item>
            <el-menu-item index="5">关注机构</el-menu-item>
            <el-menu-item index="7">观看记录</el-menu-item>
        </el-menu>
        <Detail v-if="activeIndex == 1"/>
        <Order v-if="activeIndex == 2"/>
        <Coupon v-if="activeIndex == 6"/>
        <Like v-if="activeIndex == 3"/>
        <RedEnvelope v-if="activeIndex == 4"/>
        <Org v-if="activeIndex == 5"/>
        <ViewRecord v-if="activeIndex == 7"/>
    </div>
</template>
<script>
    import Detail from './detail.vue'
    import Order from './order.vue'
    import Coupon from './coupon.vue'
    import Like from './like.vue'
    import RedEnvelope from './redEnvelope.vue'
    import Org from './org.vue'
    import ViewRecord from './viewRecord'
	export default {
		name: "UserDetail",
		data() {
			return {
				activeIndex: '1'
			}
		},
		created() {
            
		},
		components: {
            Detail,
            Order,
            Coupon,
            Like,
            RedEnvelope,
            Org,
            ViewRecord
		},
		methods: {
			handleSelect(key) { // keyPath
                this.activeIndex = key;
            }
		}
	};
</script>
<style lang="less" scoped>
.el-menu-item.is-active {
    background: content-box;
}
</style>
