<template>
	<div class="wrapper" v-loading="loading">
		<div class="flex">
			<!-- 左边 -->
			<el-form :inline='true'  label-width="100px"  style="width: 45%;display: flex;flex-direction: column;">
				<el-form-item label="用户id:">
					<div>
						{{detail.id}} 
						<el-popconfirm :title="`确定${detail.isdel ? '解除封停' : '封停'}吗？`" @confirm="isdel(detail.id)">
							<el-button style="margin-left: 10px;" slot="reference">{{ detail.isdel ? '解除封停' : '封停' }}</el-button>
						</el-popconfirm>
					</div> 
				</el-form-item>
				<el-form-item label="昵称:">
					<div>{{detail.member_name}}</div>
				</el-form-item>
				<el-form-item label="微信openid:">
					<div>{{detail.openid}}</div>
				</el-form-item>
			</el-form>
			<!-- 右边 -->
			<el-form :inline='true'  label-width="100px"  disabled style="width: 45%;display: flex;flex-direction: column;">
				<el-form-item label="手机号:">
					<div>{{detail.member_phone}}</div>
				</el-form-item>
				<el-form-item label="手机注册时间:">
					<div>{{detail.login_time}}</div>
				</el-form-item>
			</el-form>
		</div>
		<!-- 用户个人私密信息 -->
		<div class="flex">
			<el-form :inline='true'  label-width="100px"  disabled style="width: 45%;">
				<el-form-item label="学员性别:">
					<div>{{detail.gender==1?'男':'女'}}</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import { memberDetail, closure } from '@/api/base.js';
	import filter from '@/util/filters.js'
	export default {
		name: "UserDetail",
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				loading: true,
				id: this.$route.query.id,
				detail: '',
			};
		},
		mounted() {
			this.getUserDetail(this.id)
		},
		methods: {
			// 展示课程信息
			showCourseDetail() {},
			// 获取用户详细信息
			getUserDetail(id) {
				memberDetail({
					member_id: id
				}).then(res => {
					res.data.login_time = filter.formatDate(res.data.login_time * 1000)
					this.detail = res.data
					this.loading = false
					console.log(res)
				}).catch(rej => {
					console.log(rej)
				})
			},
			// 封停
			isdel(id){
				closure({
					member_id: id
				}).then((res) => {
					if(res.code == 200) {
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.getUserDetail(this.id)
					}
				}).catch(rej => {
					console.log(rej)
				})
			}
		}
	};
</script>

<style scoped>
	.flex {
		display: flex;
		justify-content: space-between;
		margin: 20px 0;
		border-bottom: 1px solid #dedede;
	}
</style>