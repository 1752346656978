<template>
    <div>
        <OrderList :userId="userId"/>
    </div>
</template>
<script>
import OrderList from '../../courseOrder/orderList.vue'
export default {
    name: "UserOrder",
    data() {
        return {
            userId: this.$route.query.id,
        }
    },
    components: {
        OrderList
    },
    created() {
        
    },
    methods: {
       
    }
};
</script>