<template>
    <div>
        <LikeLog :userId="userId"/>
    </div>
</template>
<script>
import LikeLog from '../../video/likeLog.vue'
export default {
    name: "UserLikeLog",
    data() {
        return {
            userId: this.$route.query.id,
        }
    },
    components: {
        LikeLog
    }
};
</script>