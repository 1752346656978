<template>
	<div class="wrapper" v-loading="loading">
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="focus_time" label="操作时间">
				<template slot-scope="scope">{{ scope.row.focus_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="enter_member_id" label="机构logo">
				<template slot-scope="scope">
					<img style="width:60px" :src="scope.row.logo"/>
				</template>
			</el-table-column>
			<el-table-column prop="org_id" label="机构id"></el-table-column>
			<el-table-column prop="org_name" label="机构名称"></el-table-column>
			<el-table-column prop="ShortName" label="机构城市"></el-table-column>
			<el-table-column prop="enter_member_id" label="类型">
				<template slot-scope="scope">
					<div>{{scope.row.status == 0 ? '取消关注' : '关注'}}</div>
				</template>
			</el-table-column>
			<!-- <el-table-column prop="enter_member_id" label="关注来源"></el-table-column> -->
			<el-table-column prop="video_id" label="关注视频id"></el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { focusOrg } from '@/api/base.js';
	export default {
		name: "UserOrg",
		data() {
			return {
				loading: true,
				userId: this.$route.query.id,
				tableHeight: this.$constant.maxHeight,
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
				list: [],
			}
		},
		mounted() {
			this.getList()
			this.tableHeight =this.$common.setHeight([], 250)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([], 250)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				focusOrg({
					member_id: this.userId,
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true;
				this.currentPage = val;
				this.getList();
            },
		}
	};
</script>