<template>
    <div>
        <CreditDetail :userId="userId"/>
    </div>
</template>
<script>
import CreditDetail from '../../marketing/creditDetail.vue'
export default {
    name: "UserCreditDetail",
    data() {
        return {
            userId: this.$route.query.id,
        }
    },
    components: {
        CreditDetail
    },
    created() {
        
    },
    methods: {
       
    }
};
</script>