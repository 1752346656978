<template>
	<div class="wrapper">
		<div v-show="!isDetail" v-loading="loading">
			<el-table :data="list" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
				<el-table-column prop="created_at" label="观看时间"></el-table-column>
				<el-table-column prop="watch_count" label="观看视频个数"></el-table-column>
				<el-table-column label="查看详情">
					<template slot-scope="scope">
						<el-link  type="primary" @click="toDetail(scope.row.id)">查看详情</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<div v-if="isDetail" v-loading="detailLoading">
			<el-page-header style="margin: 6px 0" @back="isDetail = false"></el-page-header>
			<el-table :data="detailList" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
				<el-table-column prop="video_id" label="观看视频id"></el-table-column>
				<el-table-column prop="watch_time" label="观看时间"></el-table-column>
				<el-table-column prop="watch_way" label="观看渠道">
					<template slot-scope="scope">
						{{ scope.row.watch_way == 'mini_program' ? '小程序' : scope.row.watch_way == 'mini_app' ? 'app' : '--' }}
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import { watchLog, watchLogDetail } from '@/api/base.js';
	export default {
		name: "ViewRecord",
		data() {
			return {
				loading: true,
				userId: this.$route.query.id,
				isDetail: false,
				// 列表
				total: 0,
				limit: 10,
				currentPage: 1,
				list: [],
				// 订单详情
				detailLoading: true,
				detailList: []
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			// 获取列表
			getList() {
				this.loading = true;
				watchLog({
					member_id: this.userId,
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			// 查看详情
			toDetail(id) {
				this.isDetail = true
				this.detailLoading = true
				watchLogDetail({id}).then((res) => {
					this.detailList = res.data;
					this.detailLoading = false
				}).catch((err) => {
					console.log(err, 222)
				})
			}
		}
	};
</script>